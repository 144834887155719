function TabPage(index) {
    this.index = index;
    this.tab = null;
    this.page = null;
    this.selectedSwitch = null;

    this.addEventHandlers = function (panel) {
        this.tab.tabIndex = 0;
        this.tab.onclick = this.createOnClickHandler(panel);
        this.tab.onkeydown = this.createOnKeyDownHandler(panel);
        this.selectedSwitch = new HtmlClassSwitch(this.tab, "Selected");
    }

    this.createOnClickHandler = function (panel) {
        var object = this;
        return function (event) { panel.selectTab(object.index, true); }
    };

    this.createOnKeyDownHandler = function (panel) {
        var object = this;

        return function (event) {
            switch (event.keyCode) {
                case 32:
                    panel.selectTab(object.index, true);
                    event.stopHandling();
                    break;
                case 37:
                    panel.selectTab(object.index - 1, true);
                    event.stopHandling();
                    break;
                case 39:
                    panel.selectTab(object.index + 1, true);
                    event.stopHandling();
                    break;
            }
        }
    };

    this.select = function (enabled, updateFocus) {
        this.selectedSwitch.setStatus(enabled);

        if (enabled)
            this.page.style.display = "block";
        else
            this.page.style.display = "none";

        if (enabled && updateFocus)
            this.tab.focus();
    }
}

function TabPanel(element) {
    WebPageComponent.call(this, element);

    this.determinePages = function () {
        var query = new DomQuery(this.element);

        var tabElements = new DomQuery(query.getChild(WithClass("Tabs"))).getChildren(WithTagName("LI"));
        var pageElements = new DomQuery(query.getChild(WithClass("Pages"))).getChildren(WithTagName("DIV"));

        for (var index = 0; index < tabElements.length; index++) {
            var tabPage = new TabPage(index);
            tabPage.tab = tabElements[index];
            tabPage.page = pageElements[index];
            tabPage.content = tabPage.page.childNodes[0];

            this.tabPages.push(tabPage);
        }
    };

    this.attachPageEventHandlers = function () {
         for (var index = 0; index < this.tabPages.length; index++)
            this.tabPages[index].addEventHandlers(this);
    }

    this.selectTab = function (newIndex, updateFocus) {
        var object = this;
        if (newIndex < 0)
            newIndex = 0;

        if (newIndex >= this.tabPages.length)
            newIndex = this.tabPages.length - 1;

        var formData = new FormData();
		formData.append("Active", newIndex);

		this.client.postFormRequest(
            this.element.dataset.Uri,
            formData,
            function (xmlRequest) {
            }
        );

        for (var index = 0; index < object.tabPages.length; index++) {
            var tabPage = object.tabPages[index];
            tabPage.select(index == newIndex, updateFocus);
        }

        for (var index = 0; index < object.tabPages.length; index++) {
            var tabPage = object.tabPages[index];

            if (tabPage.content.component !== undefined)
                tabPage.content.component.handleEvent(new VisibilityChangedEvent(object));
        }
    }

    this.tabPages = new Array();
    this.determinePages();
    this.attachPageEventHandlers();
}

interactivityRegistration.register("TabPanel", function (element) { return new TabPanel(element); });

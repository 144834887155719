function SegmentedButton(element) {
    WebPageComponent.call(this, element);

    this.addEventListener = function (event, handler) {
        for (var option of this.options)
            option.input.addEventListener(event, handler);
    }

    this.attachHandlers = function() {
        for (var option of this.options) {
            option.input.addEventListener("click", this.createSelectHandler(option));
            option.element.addEventListener("keydown", this.createKeyHandler(option));
        }
    }

    this.createKeyHandler = function(option) {
        var object= this;
        return function (event) { return object.handleKey(option, event); };
    }

    this.createSelectHandler = function(option) {
        var object = this;
        
        return function (event) { 
            object.select(option);
        };
    }

    this.determineOptions = function () {
        var options = new Array();
        var query = new DomQuery(this.element);
        var elements = query.getDescendants(WithTagName("LABEL"));

        for (var element of elements) {
            var option = new SegmentButtonElement(element);

            options.push(option);
        }

        return options;
    }

    this.focus = function() {
        this.options[0].element.focus();
    }

    this.focusNext = function(option) {
        var target = option.element.nextSibling;
        
        if (target !== null && target.tagName === "LABEL")
            target.focus();
    }

    this.focusPrevious = function(option) {
        var target = option.element.previousSibling;
        
        if (target !== null && target.tagName === "LABEL")
            target.focus();
    }

    this.getName = function () {
        return this.name;
    }

    this.getValue = function () {
        var result = null;
        var index = 0;

        while (result == null && index < this.options.length) {
            var radioButton = this.options[index].input;

            if (radioButton.checked)
                result = radioButton.value;
            else
                index++;
        }

        return result;
    }

    this.handleKey = function(option, event) {  
        if (event.code === "Space" || event.code === "Enter") 
            this.handleKeyStroke(this.handleSelectKey, option, event);
        else if (event.code === "ArrowLeft") 
            this.handleKeyStroke(this.focusPrevious, option, event);
        else if (event.code === "ArrowRight")
            this.handleKeyStroke(this.focusNext, option, event);
        else if (event.code === "Home") 
            this.handleKeyStroke(this.handleHomeKey, option, event);
        else if (event.code === "End") 
            this.handleKeyStroke(this.handleEndKey, option, event);
    }

    this.handleHomeKey = function(option) {
        this.options[0].element.focus();
    }

    this.handleEndKey = function(option) {
        this.options[this.options.length - 1].element.focus();
    }

    this.handleSelectKey = function(option) {
        this.select(option);
        option.input.dispatchEvent(new Event("change"));
    }

    this.handleKeyStroke = function(callback, option, event) {
        callback.call(this, option);
        event.preventDefault();
    }

    this.select = function(value) {
        for (var option of this.options)
            option.deselect();
        
        value.select();
    }

    this.name = this.element.dataset.Name;

    if (this.mode === ControlMode.edit) {
        this.options = this.determineOptions();
        this.options[0].element.tabIndex = 0;

        this.attachHandlers();
    }
}

function SegmentButtonElement(element) {
    this.deselect = function() {
        this.element.classList.remove("Selected");
    }

    this.initialize = function() {
        this.input = new DomQuery(element).getChild(WithTagName("INPUT"));

        if (this.input.checked)
            this.element.classList.add("Selected");
    }

    this.select = function() {
        this.input.checked = true;
        this.element.classList.add("Selected");
    }

    this.element = element;
    this.element.tabIndex = -1;
    this.initialize();
}

interactivityRegistration.register("SegmentedButton", function (element) { return new SegmentedButton(element); });

function ObjectActionComponent(element) {
    WebPageComponent.call(this, element);

    this.attachRefreshHandler = function() {
        var object = this;
        var query = new DomQuery(this.element);
        var item = query.getDescendant(WithClass("Refresh"));

        if (item !== null)   
            item.addEventListener("click", function (event) { object.refresh(); });
    }
    
    this.determineElements = function() {
        var query = new DomQuery(this.element);
        
        this.toolbar = new Toolbar(query.getDescendant(WithClass("Toolbar")));
        this.content = this.element.childNodes[1];
    }
    
    this.fireDataChanged = function () {
        distributeEvent(new DataChangedEvent(this));
    }

    this.getForm = function() {
        return this.content;
    }
    
    this.handleEvent = function (event) {
        if (event instanceof DataChangedEvent && this.refreshForSiblings)
            this.refresh();
    }
    
    this.handleHttpCommandResponse = function(xmlResponse) {
        var contentType = xmlResponse.getResponseHeader('Content-Type');

        if (contentType.slice(0, 15) == "application/xml") {

            var response = xmlResponse.responseXML;
            var query = new DomQuery(response.documentElement);

            var updateScopeElement = query.getChild(WithTagName("UpdateScope"));
            var updateScope = RenderScope.Complete;

            if (updateScopeElement !== null)
                updateScope = RenderScope.fromText(updateScopeElement.getAttribute("Value"));

            this.sendRefreshRequest(updateScope);
        }

        this.updatingCounter.decrease();
    }

    this.initialize = function() {
        this.uri = this.element.dataset.Uri;
        this.refreshForSiblings = this.element.dataset.RefreshForSiblings === "true";
    };

    this.refresh = function() {
        this.sendCommandRequest("<Refresh/>");
    }

    this.refreshWithScope = function(xmlResponse, scope) {
        var dummyElement = document.createElement("div");

        if (scope === RenderScope.Toolbar) {
            dummyElement.innerHTML = xmlResponse.responseText;

            var newToolbar = dummyElement.firstChild;
            this.toolbar.replace(newToolbar, true);

            this.attachRefreshHandler();
        }
        else {
            dummyElement.innerHTML = xmlResponse.responseText;
            dummyElement = dummyElement.firstChild;

            var newToolbar = dummyElement.childNodes[0];
            var newContent = dummyElement.childNodes[1];

            interactivityRegistration.detach(this.content);
            this.element.replaceChild(newContent, this.content);
            this.content = newContent;
            interactivityRegistration.attach(this.content);

            this.toolbar.replace(newToolbar, false);
            this.attachRefreshHandler();
        }

        this.updatingCounter.decrease();
    }

    this.refreshFromResponse = function(xmlResponse) {
        this.refreshWithScope(xmlResponse, RenderScope.Complete);
    }

    this.sendCommandRequest = function(command) {
        var object = this;

        this.updatingCounter.increase();

        this.client.sendXmlRequest(
            this.uri,
            "<Commands>" + command + "</Commands>",
            function (xmlResponse) { object.handleHttpCommandResponse(xmlResponse); }
        );
    }

    this.sendRefreshRequest = function(scope) {
        var object = this;

        this.updatingCounter.increase();
        this.client.sendXmlRequest(
            this.uri,
            "<Render Scope=\"" + RenderScope.toText(scope) + "\"/>",
            function (xmlResponse) { object.refreshWithScope(xmlResponse, scope); }
        );
    }

    this.client = new HttpClient();
    this.updatingCounter = new HtmlClassCounter(this.element, "Updating");
    
    this.initialize();
    this.determineElements();
    this.attachRefreshHandler();
}

interactivityRegistration.register("ObjectActionComponent", function (element) { return new ObjectActionComponent(element); });

function DropDown(element) {
    WebPageComponent.call(this, element);

    this.addEventListener = function(event, handler) {
        this.input.addEventListener(event, handler);
    }

    this.attachHandlers = function() {
        this.button.addEventListener("click", (event) => { this.toggleForm(); });
        this.label.addEventListener("click", (event) => { this.toggleForm(); });

        for (const option of this.options) {
            option.element.addEventListener("click", this.createToggleOptionHandler(option));
            option.element.addEventListener("keydown", this.createOptionKeyHandler(option));
        }

        this.element.addEventListener("keydown", this.createKeyHandler());
    }

    this.close = function() {
        this.dropDownToggle.setStatus(false);
        removeClickOutsideListener(this.clickOutsideListener);
    }

    this.createKeyHandler = function() {
        return (event) => {
            return this.handleKey(event);
        }
    }

    this.createOptionKeyHandler = function(option) {
        return (event) => {
            return this.handleOptionKey(option, event);
        }
    }

    this.createToggleOptionHandler = function(option) {
        return (event) => { 
            this.toggle(option); 
            return true; 
        }
    }

    this.determineElements = function() {
        var query = new DomQuery(this.element);        
        
        this.dropDown = query.getChild(WithClass("Options"));
        this.dropDownToggle = new HtmlClassSwitch(this.dropDown, "Expanded");
        
        this.button = query.getChild(WithTagName("BUTTON"));
        this.button.tabIndex = -1;

        this.label = query.getChild(WithClass("Label"));
        this.input = query.getChild(WithTagName("INPUT"));
        
        let options = new DomQuery(this.dropDown).getChildren(WithClass("Option"));

        for (const option of options) 
            this.options.push(new DropDownOption(option));
    }

    this.focus = function() {
        this.options[0].element.focus();
    }

    this.focusNext = function(option) {
        var target = option.element.nextSibling;
        
        if (target !== null && target.classList.contains("Option"))
            target.focus();
    }

    this.focusPrevious = function(option) {
        var target = option.element.previousSibling;
        
        if (target !== null && target.classList.contains("Option"))
            target.focus();
    }

    this.getName = function () {
        if (this.mode === ControlMode.edit)
            return this.input.name;
        else if (this.mode === ControlMode.display)
            return this.element.dataset.Name;
        else
            throw "Unknown control mode: " + this.mode;
    }
    
    this.getValue = function () {
        if (this.mode === ControlMode.edit)
            return this.input.value;
        else if (this.mode === ControlMode.display)
            return this.element.dataset.Value;
        else
            throw "Unknown control mode: " + this.mode;
    }

    this.handleKey = function(event) {
        if (event.ctrlKey && event.code === "Space") {
            this.toggleForm();
            event.stopPropagation();
        }
    }

    this.handleOptionKey = function(option, event) {
        if (event.code === "Space") {
            this.toggle(option);
            event.stopPropagation();
        }
        else if (event.code === "Enter") {
            this.toggle(option);
            
            if (this.isOpen())
                this.close();
        }
        else if (this.isOpen() && event.code === "Escape") {
            this.close();      
        }
        else if (event.code === "ArrowUp") {
            this.focusPrevious(option);
            event.preventDefault();
        }
        else if (event.code === "ArrowDown") {
            this.focusNext(option);
            event.preventDefault();
        }
        else if (event.code === "Home") {
            this.options[0].element.focus();
            event.preventDefault();
        }
        else if (event.code === "End") {
            this.options[this.options.length - 1].element.focus();
            event.preventDefault();
        }
    }

    this.isOpen = function() {
        return this.dropDownToggle.getStatus();
    }

    this.open = function() {
        this.dropDownToggle.setStatus(true);
        this.dropDown.scrollIntoView({block: "nearest", inline: "nearest"});
        this.clickOutsideListener = connectClickOutsideListener(this.element, (event) => { this.toggleForm(); });    

        this.focus();
    }

    this.recalculate = function() {
        for (const option of this.options) {
            if (option.selected) {
                this.setValue(option.value);
                this.label.innerHTML = option.element.innerHTML;
            }
        }
    }

    this.setValue = function(value) {
        this.input.value = value;

        this.input.dispatchEvent(new Event("input"));
        this.input.dispatchEvent(new Event("change"));
    }

    this.toggle = function(option) {
        if (!option.selected) {
            for (const other of this.options)
                if (other.selected)
                    other.toggle();

            option.toggle();
            this.recalculate();
        }
    }

    this.toggleForm = function() {
        if (this.isOpen()) 
            this.close();
        else 
            this.open();
    }

    this.element.tabIndex = 0;
    this.options = new Array();

    if (this.mode === ControlMode.edit) {      
        this.determineElements();    
        this.recalculate();
        this.attachHandlers();
    }
}

function DropDownOption(element) {
    this.setStatus = function(status) {
        this.selected = status;

        this.classSwitch.setStatus(status);
        this.element.setAttribute("aria-selected", status);
    }

    this.toggle = function() {
        this.setStatus(!this.selected);
    }
    
    this.element = element;
    this.element.tabIndex = -1;

    this.value = this.element.dataset.Value;
    this.selected = element.classList.contains("Selected");

    this.classSwitch = new HtmlClassSwitch(this.element, "Selected");
    this.selected = this.classSwitch.getStatus();
}

interactivityRegistration.register("DropDown", function (element) { return new DropDown(element); });

// TODO: Move Enumeration to separate file
function Enumeration(constants) {
    this.labels = new Array();

    for (var i in constants) {
        var constant = constants[i];
        this[constant] = i;
        this.labels[i] = constant;
    }

    this.fromText = function(value) {
        return this[value];
    }

    this.toText = function(value) {
        return this.labels[value];
    }
}

function FormButtonField (element) {
    WebPageComponent.call(this, element);

    this.captionClicked = function (event) {
        this.collapsed.toggle();

        if (!this.collapsed.getStatus()) {
            var htmlElement = document.getElementsByTagName("html")[0];
            htmlElement.addEventListener("click", this.clickOutsideListener, true);
        }

        getEvent(event).stopHandling();
    }

    this.determineElements = function () {
        var query = new DomQuery(this.element);

        this.caption = query.getDescendant(WithClass("caption"));
        this.collapsed = new HtmlClassSwitch(this.element, "collapsed");
    }

    this.removeClickOutsideListener = function () {
        var htmlElement = document.getElementsByTagName("html")[0];
        htmlElement.removeEventListener("click", this.clickOutsideListener, true);
    }

    this.close = function () {
        this.collapsed.setStatus(true);
        this.removeClickOutsideListener();
    }

    this.createClickOutsideListener = function () {
        var object = this;

        return function (event) {
            var query = new DomQuery(getEvent(event).getSource());

            if (!query.hasAncestor(object.element)) {
                object.close();
            }
        };
    }

    this.attachEventHandlers = function () {
        var object = this;

        this.caption.onclick = function (event) { object.captionClicked(getEvent(event)); };
    }

    this.clickOutsideListener = this.createClickOutsideListener();

    this.determineElements();
    this.attachEventHandlers();
}

interactivityRegistration.register("formButtonField", function (element) { return new FormButtonField(element); });

function UploadRow(element, file) {
    this.build = function() {
        this.fileNameCell = this.element.appendChild(document.createElement("td"));
        this.fileNameCell.className = "FileName";
        this.fileNameCell.innerHTML = this.file.name;

        this.progressBar = new ProgressBar(2);
        this.progressBarCell = this.element.appendChild(document.createElement("td"));
        this.progressBarCell.appendChild(this.progressBar.element);

        this.actionCell = this.element.appendChild(document.createElement("td"));
    }

    this.cancel = function() {
        this.aborted = true;
        this.button.disabled = true;
        this.button.innerHTML = "Cancelled";
        this.element.classList.add("Cancelled");
    }

    this.createCancelButton = function(cancel) {
        var object = this;

        this.button = document.createElement("button");
        this.button.innerHTML = "Cancel";
        this.button.className = "button";
        this.button.onclick = function (event) {
            object.cancel();
            return false;
        };

        this.actionCell.appendChild(this.button);
    }

    this.setProgress = function(progress, phase) {
        this.progressBar.setProgress(progress, phase);
    }

    this.element = element;
    this.file = file;

    this.aborted = false;
    this.build();
}

function UploadArea(element) {
    FileDropZone.call(this, element);

    this.build = function() {
        this.decoratedComponent = this.element.firstChild.component;

        var object = this;
        var decoratedElement = this.element.firstChild;

        if (isMobile()) {
            // TODO: We should probably render this serverside to have the appropriate label.
            this.fileInput = document.createElement("input");
            this.fileInput.type = "file";
            this.fileInput.multiple = true;
            this.fileInput.addEventListener("input", function (event) { object.handleFiles( object.fileInput.files ); });
            this.fileInput.accept = this.element.dataset.Accept;            

            this.label = document.createElement("label");
            this.label.appendChild(this.fileInput);
            this.label.appendChild(document.createTextNode("Upload"));

            this.toolbar = document.createElement("span");
            this.toolbar.className = "Toolbar";
            this.toolbar.appendChild(this.label);

            this.element.insertBefore(this.toolbar, decoratedElement);
        }

        this.uploadImage = this.element.insertBefore(document.createElement("div"), decoratedElement);
        this.uploadImage.className = "UploadImage";

        this.progressArea = this.element.insertBefore(document.createElement("div"), decoratedElement);
        this.progressArea.className = "ProgressArea";

        this.messages = this.element.insertBefore(document.createElement("ul"), decoratedElement);

        this.hidden = new HtmlClassSwitch(this.progressArea, "Hidden");
        this.hidden.setStatus(true);

        this.progressTable = this.progressArea.appendChild(document.createElement("table"));
        this.progressTable.className = "data";
        this.progressTable.appendChild(this.createTableHeaderRow());
    }

    this.createFile = function(file, uploadRow, callback) {
        var object = this;

        var xmlHttpRequest = new XMLHttpRequest();
        xmlHttpRequest.open("POST", object.URI);

        var formData = new FormData();
        formData.append("form", "Form");
        formData.append("FileName", file.name);
        formData.append("FileSize", file.size);

        xmlHttpRequest.onreadystatechange = function (event) {
            if (this.readyState === 4 && this.status === 201) {
                uploadRow.location = this.getResponseHeader("location");
                object.processFile(file, uploadRow, callback);
            }
        }
        xmlHttpRequest.send(formData);
    }

    this.createFinalCallbackFunction = function(uploadRow, callback) {
        var object = this;

        return function() {
            object.decoratedComponent.refresh();
            uploadRow.button.disabled = true;
            uploadRow.button.innerHTML = "Finished";
            uploadRow.element.classList.add("Finished");

            if (callback !== null)
                callback();
        }
    }

    this.createProcessFileFunction = function (file, uploadRow, callback) {
        var object = this;
        return function() { object.createFile(file, uploadRow, callback); };
    }

    this.createProcessFilePartFunction = function(part, uploadRow, callback) {
        var object = this;
        return function() { object.processFilePart(part, uploadRow, callback); };
    }

    this.createTableHeaderRow = function () {
        var row = document.createElement("tr");

        var fileNameHeader = row.appendChild(document.createElement("th"));
        fileNameHeader.innerHTML = "File";

        var progressBarHeader = row.appendChild(document.createElement("th"));
        progressBarHeader.innerHTML = "Progress";

        var cancelButtonHeader = row.appendChild(document.createElement("th"));
        cancelButtonHeader.innerHTML = "Action";

        return row;
    }

    this.createUploadRow = function(file) {
        var element = this.progressTable.appendChild(document.createElement("tr"));
        element.className = "UploadRow";

        return new UploadRow(element, file);
    }

    this.handleFiles = function(files) {
        this.processFiles(files);
        this.hidden.setStatus(false);
    }

    this.initialize = function() {
        this.URI = this.element.dataset.Uri;
        this.maximumSize = parseInt(this.element.dataset.MaximumSize);
        this.allowPartitioning = this.element.dataset.AllowPartition === "true";

        if (this.allowPartitioning)
            this.partSize = parseInt(this.element.dataset.PartSize);
        else
            this.partSize = this.maximumSize;

        this.initializeDropZone();
    }

    this.processFilePart = function(part, uploadRow, callback) {
        var object = this;

        if (!uploadRow.aborted) {
            var formData = new FormData();
            formData.append("form", "Form");
            formData.append("FileName", part.name);
            formData.append("Index", part.index);
            formData.append("Contents", part.blob);

            var xmlHttpRequest = new XMLHttpRequest();
            xmlHttpRequest.open("POST", uploadRow.location);

            xmlHttpRequest.upload.onprogress = function (event) {
                if (uploadRow.aborted)
                    xmlHttpRequest.abort();

                if (event.lengthComputable) {
                    var subTarget = (part.offset + part.blob.size) / uploadRow.file.size;
                    var progress = ((part.offset + (part.blob.size * event.loaded / event.total)) / uploadRow.file.size);
                    uploadRow.setProgress([subTarget * 100, progress * 100], 1);
                }
            };

            xmlHttpRequest.onreadystatechange = function (event) {
                if (this.readyState === 4 && callback !== null)
                    callback();
            }

            xmlHttpRequest.send(formData);
        }
        else if (callback !== null)
            callback();
    }

    this.processFile = function (file, uploadRow, callback) {
        var object = this;
        var reader = new FileReader();

        reader.onload = function(event) {
            var bytes = new Uint8Array(event.target.result);

            uploadRow.createCancelButton();

            var parts = new Array();
            var offset = 0;
            var remainder = file.size;

            while (remainder > 0) {
                var partSize = Math.min(remainder, object.partSize);
                parts.push({"offset": offset, "size": partSize});

                offset += partSize;
                remainder = file.size - offset;
            }

            var callback_ = object.createFinalCallbackFunction(uploadRow, callback);

            for (var index = parts.length - 1; index >= 0; index--) {
                var part = parts[index];
                part.blob = new Blob([bytes.subarray(part.offset, part.offset + part.size)]);
                part.name = file.name;
                part.index = index;

                callback_ = object.createProcessFilePartFunction(part, uploadRow, callback_);
            }

            if (callback_ !== null)
                callback_();
        };
        reader.readAsArrayBuffer(file);
    }

    this.processFiles = function (files) {
        var uploadRows = new Array();

        for (var i = 0; i < files.length; i++)
            uploadRows.push(this.createUploadRow(files[i]));

        var object = this;
        var callback = null;

        for (var index = files.length - 1; index >= 0; index--) {
            var file = files[index];
            var uploadRow = uploadRows[index];

            if (!this.validateFile(file, this.element.dataset.Accept)) 
                uploadRow.progressBarCell.innerHTML = "Unsupported file type";
            else if (file.size > this.maximumSize)
                uploadRow.progressBarCell.innerHTML = "Cannot upload file. File size over " + this.maximumSize / (1000 * 1000) + "MB";
            else 
                callback = this.createProcessFileFunction(file, uploadRow, callback);
        }

        if (callback !== null)
            callback();
    }

    this.build();
    this.initialize();
}

interactivityRegistration.register("UploadArea", function (element) { return new UploadArea(element); });

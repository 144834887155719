function RecordSetField(element) {
    WebPageComponent.call(this, element);

    this.createInsertHandler = function () {
        var object = this;

        return function (event) {
            object.insertRow();
        };
    };

    this.createRemoveHandler = function (row) {
        var object = this;

        return function (event) {
            object.removeRow(row);
        };
    };

    this.insertRow = function () {
        this.maximumRowIndex++;

        var rowIndex = this.maximumRowIndex;
        var xmlRequest = newXmlRequest();
        var commands = "<insertRow index=\"" + rowIndex + "\"/>";
        var object = this;

        xmlRequest.open("POST", this.URI, true);
        xmlRequest.setRequestHeader("Content-Type", "application/xml");
        xmlRequest.onreadystatechange = function () { object.handleInsertRowResponse(xmlRequest, rowIndex); };
        xmlRequest.send(commands);
    };

    this.removeRow = function (row) {
        this.rowIndices.splice(row.rowIndex - 1, 1);
        this.updateRowIndicesField();
        
        row.parentNode.removeChild(row);
        this.valueChanged();
    }

    this.updateRowIndicesField = function () {
        this.rowIndicesField.value = this.rowIndices.join(", ");
    };

    this.valueChanged = function() {
        if (this.rowIndicesField.onchange !== null)
            this.rowIndicesField.onchange();
    }

    this.handleInsertRowResponse = function (xmlRequest, rowIndex) {
        if (xmlRequest.readyState == 4) {
            var response = xmlRequest.responseXML;
            var rowContainer = document.createElement("div");
            rowContainer.innerHTML = "<table>" + xmlRequest.responseText + "</table>";

            var row = rowContainer.getElementsByTagName("tr")[0];
            this.addButtonToRow(row, this.createRemoveButton(row));

            var tBody;

            if (this.table.tBodies.length === 0) {
                tBody = document.createElement("tbody");
                this.table.appendChild(tBody);
            }
            else
                tBody = this.table.tBodies[0];

            tBody.appendChild(row);

            this.rowIndices.push(rowIndex);
            this.updateRowIndicesField();

            interactivityRegistration.attach(row);
            this.valueChanged();
        }
    }

    this.determineElements = function () {
        if (this.mode === ControlMode.edit) {
            var form = new DomQuery(this.element).getAncestor(WithTagName("FORM"));
            this.rowIndicesField = form.elements[this.name + ".RowIndices"];
        }
    };

    this.createButtonCell = function () {
        var result = document.createElement("td");
        var classes = new HtmlClasses(result);
        classes.add("button");

        return result;
    };

    this.createButton = function (className, onclick) {
        var result = document.createElement("div");
        var classes = new HtmlClasses(result);

        classes.add("Action");
        classes.add(className);

        result.tabIndex = 0;
        result.onclick = onclick;

        return result;
    };

    this.createInsertButton = function () {
        return this.createButton("insert", this.createInsertHandler());
    };

    this.createRemoveButton = function (row) {
        return this.createButton("remove", this.createRemoveHandler(row));
    };

    this.addButtonToRow = function (row, button) {
        var buttonCell = this.createButtonCell();

        buttonCell.appendChild(button);
        row.insertBefore(buttonCell, row.childNodes[0]);
    };

    this.createElements = function () {
        this.table = this.element.getElementsByTagName("table")[0];

        if (this.mode === ControlMode.edit) {
            this.addButtonToRow(this.table.rows[0], this.createInsertButton());

            for (var i = 1; i < this.table.rows.length; i++) {
                var row = this.table.rows[i];
                this.addButtonToRow(row, this.createRemoveButton(row));
            }
        }
    };

    this.determineRowIndices = function () {
        if (this.mode === ControlMode.edit) {
            var value = this.rowIndicesField.value;
            var rowIndexTexts;

            if (value != "")
                rowIndexTexts = value.split(", ");
            else
                rowIndexTexts = new Array();

            var result = new Array(rowIndexTexts.length);

            this.maximumRowIndex = -1;

            for (var i = 0; i < rowIndexTexts.length; i++) {
                var rowIndex = parseInt(rowIndexTexts[i]);
                result[i] = rowIndex;

                if (rowIndex > this.maximumRowIndex)
                    this.maximumRowIndex = rowIndex;
            }

            return result;
        }
        else
            return null;
    };

    this.attachRowClickHandlers = function() {
        var rows = this.table.tBodies[0].rows;

        for (var i = 0; i < rows.length; i++) {
            var row = rows[i];

            if (row.dataset.Uri != undefined) {
                var uri = row.dataset.Uri;

                row.onclick = this.createRowClickHandler(uri);
                row.onmousemove = this.createRowHotHandler(row, true);
                row.onmouseout = this.createRowHotHandler(row, false);
            }
        }
    }

    this.createRowClickHandler = function(uri) {
        return function (event) { openUrl(event, uri); };
    }

    this.createRowHotHandler = function(row, flag) {
        return function (event) { setHot(row, event, flag); };
    }

    this.maximumRowIndex = -1;
    this.name = this.element.dataset.Name;
    this.URI = this.element.dataset.Uri;
    this.determineElements();
    this.rowIndices = this.determineRowIndices();
    this.createElements();
    this.attachRowClickHandlers();
}

interactivityRegistration.register("RecordSet", function (element) { return new RecordSetField(element); });

function WebSiteObjectFilters(element) {
    WebPageComponent.call(this, element);

    this.attachHandlers = function() {
        var object = this;

        for (var field of this.defaultFields)
            field.addEventListener("change", function (event) { object.filter(); });

        if (this.button != null)
            this.button.onclick = function (event) { object.toggleAddForm(); };

        for (var value of this.values) {
            var close = new DomQuery(value).getChild(WithClass("Close"));
            close.onclick = this.createClearFilterHandler(value);
        }
    }

    this.createClearFilterHandler = function(value) {
        var object = this;
        var name = value.childNodes[1].dataset.Name;

        return function (event) {
            object.toolbar.removeChild(value);

            for (var field of object.fields) {
                if (field.getName().indexOf(name, field.getName().length - name.length) !== -1) {
                    field.setValue("");
                    object.filter();
                }
            }
        };
    }

    this.determineElements = function() {
        var object = this;

        var query = new DomQuery(this.element);

        this.toolbar = query.getChild(WithClass("Toolbar"));
        this.progress = new Progress(query.getChild(WithClass("Progress")));
        this.content = this.element.childNodes[3];
        
        query = new DomQuery(this.toolbar);
        this.add = query.getChild(WithClass("Add"));

        if (this.add !== null) {
            this.button = new DomQuery(this.add).getChild(WithClass("Button"));
            this.addExpanded = new HtmlClassSwitch(this.add, "Expanded");

            this.filterForm = new DomQuery(this.add).getChild(WithTagName("FORM"));
            this.filterForm.childNodes[1].childNodes[0].onclick = function() { object.toggleAddForm(); object.filter(); };

            this.registerFilters(new DomQuery(this.filterForm.childNodes[0]).getDescendants(WithClass("Field")), false);
        }

        this.defaultFilters = query.getDescendants(WithClass("DefaultFilter"));

        for (var defaultFilter of this.defaultFilters)
            this.registerFilters(new DomQuery(defaultFilter).getDescendants(WithClass("Field")), true);

        this.values = query.getDescendants(WithClass("FilterValue"));
    }

    this.filter = function() {
        var client = new HttpClient();
        var object = this;

        var requestUri = this.getUri();

        client.get(
            requestUri + "/Filters",
            function (xmlRequest) {
                var dummy = document.createElement("div");
                dummy.innerHTML = xmlRequest.responseText;

                var newFilter = dummy.childNodes[0];

                object.element.parentNode.replaceChild(newFilter, object.element);
                interactivityRegistration.attach(newFilter);
                window.history.pushState(null, "", requestUri);
            },
            this.progress
        );
    }

    this.getQuery = function() {
        var query = "";

        for (var field of this.fields) {
            var name = field.getName();
            var value = field.getValue();

            if (value !== null && value.length > 0)
                query = query + encodeURIComponent(name) + "=" + encodeURIComponent(value) + ";";
        }

        if (query.length > 0)
            query = query.substring(0, query.length - 1);

        return query;
    }

    this.getUri = function() {
        // TODO: provide base uri server side.
        var uri = this.uri.split(';', 1);
        var query = this.getQuery();
        
        if (query.length > 0)
            uri = uri + ";" + query;

        return uri;
    }

    this.registerFilters = function(fields, defaultFilter) {
        for (var field of fields) {
            this.fields.push(field.component);

            if (defaultFilter)
                this.defaultFields.push(field.component);
        }
    }

    this.toggleAddForm = function() {
        var object = this;
        this.addExpanded.toggle();

        var listener = connectClickOutsideListener(
            object.add,
            function(event){
                object.addExpanded.toggle();
                removeClickOutsideListener(listener);
            }
        );
    }

    this.fields = new Array();
    this.defaultFields = new Array();
    this.uri = this.element.dataset.Uri;

    this.determineElements();
    this.attachHandlers();
}

interactivityRegistration.register("Filtering", function (element) { return new WebSiteObjectFilters(element); });

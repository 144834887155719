function ConfirmationWindow(element) {
	WebPageComponent.call(this, element);
	
	this.determineElements = function() {
		var query = new DomQuery(this.element);
		
		this.submit = query.getDescendant(WithClass("Submit"));
		this.cancel = query.getDescendant(WithClass("Cancel"));
	}

    this.show = function (onSuccess) {
        var object = this;

		this.submit.onclick = function() {
            object.expandedSwitch.setStatus(false);
			onSuccess();
		}

		this.cancel.onclick = function() {
            object.expandedSwitch.setStatus(false);
		}

        this.expandedSwitch.setStatus(true);
    }

	this.expandedSwitch = new HtmlClassSwitch(this.element, "Expanded");
	this.determineElements();
}

function ObjectAction(element) {
    WebPageComponent.call(this, element);

    this.canSubmit = true; // TODO: We should properly disable any other action while any operation is still in progress

    this.attachEventHandlers = function() {
        var object = this;

        if (this.caption !== null) 
            this.caption.addEventListener("click", function (event) { object.open(); });
        
        if (this.submit !== null) 
            this.submit.addEventListener("click", function (event) { object.submitForm() });

        if (this.cancel !== null) 
            this.cancel.addEventListener("click", function (event) { object.cancelForm() });
    }

    this.bind = function() {
        var object = this;
        var form = this.getForm();

        if (form !== null) { 
            form.onkeydown = function (event) { object.handleHotKey(getEvent(event)); };

            if (form !== null && form.component !== undefined)
                form.component.focus();
        }
    }

    this.cancelForm = function() {
        var parent = this.parentComponent;
        parent.sendCommandRequest("<Action Name=\"\"/>");
    }
    
    this.determineElements = function() {
        var query = new DomQuery(this.element);
        
        this.caption = query.getChild(WithClass("Caption"));
        this.submit  = query.getChild(WithClass("Submit"));
        this.cancel  = query.getChild(WithClass("Cancel"));
		
		if (this.requiresDoubleConfirmation)
			this.confirmationWindow = new ConfirmationWindow(query.getChild(WithClass("Confirmation")));
    }
    
	this.execute = function() {
		var object = this;
		var parent = this.parentComponent;
		var form = this.getForm();
		var xmlRequest = newXmlRequest();
		
		var formData;
		
		if (form !== null)
			formData = new FormData(form);
		else
			formData = new FormData();
			
		formData.append("Action", this.name);
		parent.updatingCounter.increase();
		
        xmlRequest.open("POST", parent.uri, true);
        xmlRequest.setRequestHeader("Accept", "text/html;q=1, */*;q=0.9")
		xmlRequest.onreadystatechange = function () {
			if (xmlRequest.readyState == 4) {
				if (xmlRequest.status === 200) {
					var contentType = xmlRequest.getResponseHeader("Content-Type");
					
					if (contentType.slice(0, 9) == "text/html") {
                        parent.toolbar.state = ToolbarState.Warning;
						parent.refreshFromResponse(xmlRequest);
						parent.fireDataChanged();
					}
					else if (contentType.slice(0, 15) == "application/xml") {
                        var response = xmlRequest.responseXML;

                        if (response.documentElement.hasAttribute("Location"))
						    object.handleRedirectResponse(xmlRequest);
                        else {
                            parent.toolbar.state = ToolbarState.Submitted;
                            parent.handleHttpCommandResponse(xmlRequest);
                            parent.fireDataChanged();
                        }
					}
				} 
				else if (xmlRequest.status === 409 || xmlRequest.status === 500) {
					var division = document.createElement("div");
					division.innerHTML = xmlRequest.responseText;
					
					var error = new DomQuery(division).getDescendant(WithClass("Error"));
					
					parent.toolbar.element.classList.add("Error");
					parent.toolbar.actionFeedback.innerHTML = "";
					parent.toolbar.actionFeedback.appendChild(error);
					parent.updatingCounter.decrease();
				}   
				
				object.canSubmit = true;
			}
		};
		
		xmlRequest.send(formData);
	}

    this.getForm = function() {
        if (this.useSiblingComponent)
            return this.parentComponent.getForm();
        else if (this.parentComponent.toolbar.actionForm !== null)
            return new DomQuery(this.parentComponent.toolbar.actionForm).getDescendant(WithTagName("FORM"));
    }

    this.handleHotKey = function(event) {
        if (event.ctrlKey && (event.key === "s" || event.key === "S")) {
            this.submitForm();
            event.preventDefault();
        }
        else if (event.key === "Escape") {
            this.cancelForm();
        }
    }
	
	this.handleRedirectResponse = function(xmlRequest) {
		var object = this;
		var parent = this.parentComponent;
		var response = xmlRequest.responseXML;
		var type = response.documentElement.getAttribute("Type");
		var redirect = response.documentElement.getAttribute("Location");
		
		if (type == "Download") {
			window.location = redirect;
			parent.updatingCounter.decrease();
			object.cancelForm();
		}
		else if (type == "Redirect") {
			window.location = redirect;
		}
	}		

    this.open = function() {
        if (!this.requiresConfirmation)
            this.execute();
        else
            this.showForm();
    }
    	
    this.submitForm = function() {
        if (this.canSubmit) {
            this.canSubmit = false;

			if (this.requiresDoubleConfirmation) {
				this.showConfirmationWindow();
				this.canSubmit = true;
			}
			else 
				this.execute();
        }
    }
	
	this.showConfirmationWindow = function() {
		var object = this;

		this.confirmationWindow.show(
            function () {
    			object.execute();
            }
        );
	}
    
    this.showForm = function() {
        var parent = this.parentComponent;
        parent.sendCommandRequest("<Action Name=\"" + this.name + "\"/>");
    }
        
    this.name = element.dataset.Name;
    this.uri = element.dataset.Uri;
	this.requiresConfirmation = element.dataset.RequiresConfirmation === "true";
	this.requiresDoubleConfirmation = element.dataset.RequiresDoubleConfirmation === "true";
    this.useSiblingComponent = element.dataset.UseSiblingComponent === "true";
	
    this.determineElements();

    if (!this.element.classList.contains("Disabled"))
        this.attachEventHandlers();
}

function ActionGroup(element) {
    this.initialize = function() {
        this.collapsed = this.element.dataset.Collapsed === "true";
        this.icon = new DomQuery(this.element).getChild(WithClass("Icon"));
        this.caption = new DomQuery(this.element).getChild(WithClass("Caption"));

        if (this.collapsed) {
            this.collapsed = new HtmlClassSwitch(this.element, "Collapsed");
            
            var object = this;
            this.icon.onclick = function(event) {
                object.collapsed.toggle();
                var listener = connectClickOutsideListener(object.element, function(event){ object.collapsed.toggle(); removeClickOutsideListener(listener); });
            };

            this.caption.onclick = function(event) {
                object.collapsed.toggle();
                var listener = connectClickOutsideListener(object.element, function(event){ object.collapsed.toggle(); removeClickOutsideListener(listener); });
            };
        }
    }

    this.element = element;
    this.initialize();
}

interactivityRegistration.register("ObjectAction", function (element) { return new ObjectAction(element); });

var Orientation = new Enumeration(["Horizontal", "Vertical"]);

function Menu(element) {
    WebPageComponent.call(this, element);

    this.updateMenuItem = function (menuItem, command) {
        if (this.uri !== undefined) {
            var itemId = menuItem.getElementsByTagName("span")[0].dataset.Id;
            var xmlRequest = newXmlRequest();
            var commands = "<Commands><" + command + " Item=\"" + escapeXMLAttributeValue(itemId) + "\"/></Commands>";

            xmlRequest.open("POST", this.uri, true);
            xmlRequest.setRequestHeader("Content-Type", "application/xml");
            xmlRequest.send(commands);
        }
    }

    this.expand = function (element, event) {
        var classes = new HtmlClasses(element);
        
        if (!classes.contains("expanded")) {
            classes.add("expanded");
            classes.remove("collapsed");

            this.updateMenuItem(element, "Expand");
            this.attachItemHandlers(element, false);
        }
    }
    
    this.collapse = function(element, event) {
        var classes = new HtmlClasses(element);
        
        if (!classes.contains("collapsed")) {
            classes.add("collapsed");
            classes.remove("expanded");
        
            this.updateMenuItem(element, "Collapse");
            this.attachItemHandlers(element, true);
        }
    }

    this.attachItemHandlers = function (element, collapsed) {
        var object  = this;
        var span    = element.getElementsByTagName("span")[0];
        var classes = new HtmlClasses(span);

        classes.add("Action");
        
        if (collapsed) {
            span.onclick = function (event) { 
                if (shouldHandleMouseClick(element, getEvent(event).getSource()))
                    object.expand(element, getEvent(event));
            };  
            
            if (object.isTopLevelMenu(element) && element.outsideListener != null)
                removeClickOutsideListener(element.outsideListener);
        }
        else {
            span.onclick = function (event) { 
                if (shouldHandleMouseClick(element, getEvent(event).getSource()))
                    object.collapse(element, getEvent(event));
            };
            
            if (object.isTopLevelMenu(element))
                element.outsideListener = connectClickOutsideListener(element, function (event) { object.collapse(element, getEvent(event));});
        }
    }
    
    this.isTopLevelMenu = function(element) {
        var classes = new HtmlClasses(element.parentNode.parentNode);
        return (classes.contains("Menu") && classes.contains("Horizontal"));
    }

    this.attachItemsHandlers = function (elements, collapsed) {
        for (var i = 0; i < elements.length; i++)
            this.attachItemHandlers(elements[i], collapsed);
    }

    this.attachSlideOutHandler = function (toolbar, handler) {
        var toggle = new DomQuery(toolbar).getChild(WithClass("Toggle"));
        var classes = new HtmlClasses(toggle);

        classes.add("Action");
        toggle.onclick = handler;
    }

    this.booleanToAttribute = function (value) {
        if (value)
            return "True";
        else
            return "False";
    }

    this.sendIconizeCommand = function (value) {
        if (this.uri !== undefined) {
            var xmlRequest = newXmlRequest();
            var commands = "<Commands><Iconize" + " Value=\"" + this.booleanToAttribute(value) + "\"/></Commands>";

            xmlRequest.open("POST", this.uri, true);
            xmlRequest.setRequestHeader("Content-Type", "application/xml");
            xmlRequest.send(commands);
        }
    }

    this.sendSlideOutCommand = function (value) {
        if (this.uri !== undefined) {
            var xmlRequest = newXmlRequest();
            var commands = "<Commands><SlideOutLevel" + " Value=\"" + value + "\"/></Commands>";

            xmlRequest.open("POST", this.uri, true);
            xmlRequest.setRequestHeader("Content-Type", "application/xml");
            xmlRequest.send(commands);
        }
    }

    this.toggleIconizedState = function () {
        var classes = new HtmlClasses(this.element);

        if (classes.contains("Expanded")) {
            classes.remove("Expanded");
            classes.add("Collapsed");

            this.sendIconizeCommand(true);
        }
        else {
            classes.remove("Collapsed");
            classes.add("Expanded");

            this.sendIconizeCommand(false);
        }

        setTimeout(
            function() {
                distributeEvent(new AvailableSizeChangedEvent());
            },
            500
        );
    }

    this.attachSlideOutHandlers = function () {
        var object = this;
        var firstLevelToolbar = new DomQuery(this.element).getChild(WithClass("Toolbar"));

        this.attachSlideOutHandler(
            firstLevelToolbar,
            function (event) {
               object.toggleIconizedState();
            }
        );

        var secondLevelMenu = new DomQuery(this.element).getChild(WithTagName("OL"));
        var menuItems = new DomQuery(secondLevelMenu).getChildren(WithTagName("LI"));

        for (var index = 0; index < menuItems.length; index++) {
            var menuItem = menuItems[index];
            var toolbar = new DomQuery(menuItem).getDescendant(WithClass("Toolbar"));

            if (toolbar !== null) {
                if (new HtmlClasses(menuItem).contains("pathCurrent"))
                    this.itemHasToolbar.setStatus(true);

                this.attachSlideOutHandler(
                    toolbar,
                    function (event) {
                        if (object.getSlideOutLevel() === "2")
                            object.setSlideOutLevel("1");
                        else
                            object.setSlideOutLevel("2");

                        object.sendSlideOutCommand(object.getSlideOutLevel());

                        setTimeout(
                            function() {
                                distributeEvent(new AvailableSizeChangedEvent());
                            },
                            500
                        );
                    }
                );
            };
        }

        var plusLevelMenus = new DomQuery(secondLevelMenu).getDescendants(WithTagName("OL"));

        for (var index = 0; index < plusLevelMenus.length; index++) {
            var plusLevelMenu = plusLevelMenus[index];

            this.attachItemsHandlers(new DomQuery(plusLevelMenu).getDescendants(WithClass("collapsed")), true);
            this.attachItemsHandlers(new DomQuery(plusLevelMenu).getDescendants(WithClass("expanded")), false);
        }
    }

    this.getSlideOutLevel = function () {
        return this.element.getAttribute("data-slide-out-level");
    }

    this.setSlideOutLevel = function (level) {
        this.element.setAttribute("data-slide-out-level", level);
    }

    this.determineElements = function () {
        var query = new DomQuery(this.element);
        if (this.type === "SlideOut") {
            this.attachSlideOutHandlers();
        }
        else if (this.type !== "SlideOut") {
            this.attachItemsHandlers(query.getDescendants(WithClass("collapsed")), true);
            this.attachItemsHandlers(query.getDescendants(WithClass("expanded")), false);
        }
    }

    this.attachKeyboardInteractivity = function() {
        var component = this;

        this.element.addEventListener("keydown", function(event) {
            switch (event.code) {
                case "ArrowLeft":
                    if (component.orientation === Orientation.Horizontal && component.focusedItem.parent === component.item)
                        component.focusPreviousItem();
                    else
                        component.focusParentItem();

                    event.preventDefault();
                    break;
                case "ArrowRight":
                    if (component.orientation === Orientation.Horizontal && component.focusedItem.parent === component.item)
                        component.focusNextItem();
                    else
                        component.focusChildItem();

                    event.preventDefault();
                    break;
                case "ArrowUp":
                    if (component.orientation === Orientation.Horizontal && component.focusedItem.parent === component.item)
                        component.focusParentItem();
                    else
                        component.focusPreviousItem();

                    event.preventDefault();
                    break;
                case "ArrowDown":
                    if (component.orientation === Orientation.Horizontal && component.focusedItem.parent === component.item)
                        component.focusChildItem();
                    else
                        component.focusNextItem();

                    event.preventDefault();
                    break;
                case "Space":
                    component.focusChildItem();
                    event.preventDefault();
                    break;
                case "Escape":
                    component.focusParentItem();
                    event.preventDefault();
                    break;
            }
        });
    }

    this.focus = function(item) {
        if (item !== null) {
            this.focusedItem = item;

            if (this.focusedItem.link !== null)
                this.focusedItem.link.focus();
        }
    }

    this.focusChildItem = function() {
        if (this.focusedItem.items.length > 0) {
            if (this.focusedItem.isExpanded())
                this.focus(this.focusedItem.getChild());
            else if (this.focusedItem.expandable)
                this.expand(this.focusedItem.element);
        }
        else if (this.focusedItem.parent !== this.item)
            this.focus(this.focusedItem.parent.getNextSibling());
    }

    this.focusNextItem = function() {
        this.focus(this.focusedItem.getNextSibling());
    }

    this.focusParentItem = function() {
        if (this.focusedItem.expandable && this.focusedItem.isExpanded())
            this.collapse(this.focusedItem.element);
        else if (this.focusedItem.parent !== this.item)
            this.focus(this.focusedItem.parent);
    }

    this.focusPreviousItem = function() {
        this.focus(this.focusedItem.getPreviousSibling());
    }

    this.initialize = function() {
        if (this.element.classList.contains("Horizontal"))
            this.orientation = Orientation.Horizontal;
        else if (this.element.classList.contains("Vertical"))
            this.orientation = Orientation.Vertical;
        else
            throw new Error("Unspecified menu orientation");

        this.item = new MenuItem(this.element, null);

        if (this.item.items.length > 0) {
            this.item.items[0].setFocusable(this);
            this.focusedItem = this.item.items[0];
        }
    }

    this.uri = this.element.dataset.Uri;
    this.type = this.element.dataset.Type;
    this.itemHasToolbar = new HtmlClassSwitch(element, "ItemHasToolbar");
    this.determineElements();

    this.initialize();
    this.attachKeyboardInteractivity();
}

function MenuItem(element, parent) {
    this.getChild = function() {
        if (this.items.length > 0)
            return this.items[0];
    }

    this.getNextSibling = function() {
        if (this.parent !== null) {
            var index = this.parent.items.indexOf(this);
            var nextIndex = (index + 1) % this.parent.items.length;
            return this.parent.items[nextIndex];
        }
        else
            return null;
    }

    this.getPreviousSibling = function() {
        if (this.parent !== null) {
            var index = this.parent.items.indexOf(this);
            var previousIndex = (index - 1) % this.parent.items.length;

            if (previousIndex < 0)
                previousIndex = previousIndex + this.parent.items.length;

            return this.parent.items[previousIndex];
        }
        else
            return null;
    }

    this.initialize = function() {
        this.label = new DomQuery(this.element).getChild(WithTagName("SPAN"));

        if (this.label !== null) {
            this.link = this.label.childNodes[0];
            this.link.tabIndex = -1;

            this.expandable = this.label.classList.contains("Action");
        }
        else
            this.expandable = false;

        var list = new DomQuery(this.element).getChild(WithTagName("OL"));

        if (list !== null) {
            var elements = new DomQuery(list).getChildren(WithTagName("LI"));

            for (var element of elements)
                this.items.push(new MenuItem(element, this));
        }
    }

    this.isExpanded = function() {
        return this.element.classList.contains("expanded");
    }

    this.setFocusable = function(component) {
        var item = this;

        if (this.link !== null) {
            this.link.tabIndex = 0;
            this.link.addEventListener("focus", function(event) { component.focusedItem = item; });
        }
    }

    this.element = element;
    this.parent = parent;
    this.items = new Array();
    this.initialize();
}

interactivityRegistration.register(
    "Menu",
    function (element) {
        if (element.tagName === "DIV")
            return new Menu(element);
        else
            return null;
    }
);
